import Header from "./components/header/Header";
import Landing from "./pages/landing/Landing";

import "./App.scss";
import * as profile from "./assets/profile.json";
// import Introduction from "./pages/introduction/Introduction";

function App() {
  return (
    <div className="App">
      <Header profile={profile} />
      <Landing profile={profile} />
      {/* <Introduction profile={profile} /> */}
      {/* <Experience profile={profile} /> */}
      {/* <Skills profile={profile} /> */}
      {/* <Projects profile={profile} /> */}
    </div>
  );
}

export default App;
