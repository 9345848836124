import React from "react";
import "./landing.scss";
// import LandingContent from "./landingContent/landingContent";
import LandingBadge from "./landingBadge/LandingBadge";

const Landing = ({ profile }) => {
  return (
    <div id="Landing">
      <div className="position-absolute top-0 w-100 h-100">
        <LandingBadge />
      </div>
    </div>
  );
};

export default Landing;
