import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Link, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { ReactComponent as LogoWhite } from "./../../assets/logos/vsWhite.svg";

const Header = ({ profile }) => {
  const StackOverflowIcon = (
    <SvgIcon fontSize="small">
      <path d="M15 21h-10v-2h10v2zm6-11.665l-1.621-9.335-1.993.346 1.62 9.335 1.994-.346zm-5.964 6.937l-9.746-.975-.186 2.016 9.755.879.177-1.92zm.538-2.587l-9.276-2.608-.526 1.954 9.306 2.5.496-1.846zm1.204-2.413l-8.297-4.864-1.029 1.743 8.298 4.865 1.028-1.744zm1.866-1.467l-5.339-7.829-1.672 1.14 5.339 7.829 1.672-1.14zm-2.644 4.195v8h-12v-8h-2v10h16v-10h-2z" />
    </SvgIcon>
  );

  return (
    <div
      id="Header"
      className="w-100 position-fixed d-flex justify-content-between p-2"
      style={{ top: 0, right: 0, zIndex: 999 }}
    >
      <div>
        <LogoWhite />
      </div>
      <div className="d-flex gap-2">
        <Tooltip
          title={"Download Resume"}
          placement="bottom"
          disableInteractive
        >
          <Link target="_blank" href={`${profile?.resume}`}>
            <DownloadIcon />
          </Link>
        </Tooltip>
        <Link target="_blank" href={`${profile?.contact?.links.github}`}>
          <GitHubIcon />
        </Link>
        <Link target="_blank" href={`${profile?.contact?.links.linkedin}`}>
          <LinkedInIcon />
        </Link>
        <Link target="_blank" href={`${profile?.contact?.links.stackoverflow}`}>
          {StackOverflowIcon}
        </Link>
      </div>
    </div>
  );
};

export default Header;
